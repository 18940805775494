import { Scene } from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
import _ from "lodash";
// import Phaser from "phaser";

export default class PuzzleScene extends Scene {
  constructor() {
    super({ key: "PuzzleScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round;
    this.qCount = data.qCount;
    this.questions = data.questions;
    this.score = data.score ? data.score : 0;
    this.friend = data.friend;
    this.forceFriend = data.forceFriend;
    this.countCorrect = data.countCorrect;
    this.difficulty = data.difficulty;
    this.noOfRounds = data.noOfRounds;
    this.noOfCuts = data.noOfCuts;
    this.bossImage = data.bossImage;
    this.currentCampTileX = data.currentCampTileX;
    this.nextCampTileX = data.nextCampTileX;
    this.totalBones = data.totalBones;
    this.collectedBones = data.collectedBones;
    this.collectedSkull = data.collectedSkull;
    this.lampTime = data.lampTime;
  }

  playerAvatarObject = [];

  create() {
    this.registerSounds();

    this.imgBackground = this.add
      .image(0, 0, "imgPuzzleBg")
      .setDisplaySize(480, 854)
      .setOrigin(0);

    this.tutorialArrow = this.add.image(350, 450, "imgTutArrowY").setDepth(99);
    this.tutorialArrow.setScale(0.8);
    this.tutorialArrow.setFlipX(true);
    this.tutorialArrow.setAngle(-110);

    this.tutorialTxt = this.add
      .text(240, 325, i18n.t("miniGames.dino.dragDrop"), {
        color: "#000",
        fontSize: "30px",
        fontFamily: "Sassoon, sans-serif",
        align: "center",
        wordWrap: { width: 200 },
      })
      .setDepth(8)
      .setOrigin(0.5, 0);

    this.screen2 = this.add
      .rectangle(240, 427, 480, 854, 0xffffff)
      .setDepth(99);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this)
      .setDepth(7);

    this.tweens.add({
      targets: this.screen2,
      alpha: 0,
      duration: 1000,
      ease: "Sine.easeInOut",
      onComplete: () => {},
    });

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 750, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 750, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 750, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: "-=10",
      duration: 1000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    // always square like 140x140 and cut 3X3
    const imageWidth = 420;
    const cutCount = this.noOfCuts;
    const pieceWidth = imageWidth / cutCount;
    const paddingAround = (480 - imageWidth) / 2;
    const marginTop = 50;
    let background = this.textures.get("imgDino2").getSourceImage();
    let foreground = this.textures.get("imgCollection").getSourceImage();

    let mergedImage = this.mergeImages(
      background,
      foreground,
      imageWidth,
      imageWidth
    );

    this.textures.addImage("mergedImage", mergedImage);

    this.boardBoxGroup = this.add.group();

    this.puzzlePieceGroup = this.add.group();

    let swapper = [];

    for (let i = 0; i < cutCount; i++) {
      for (let j = 0; j < cutCount; j++) {
        const boardBox = this.add
          .rectangle(
            pieceWidth * j + paddingAround,
            pieceWidth * i + paddingAround + marginTop,
            pieceWidth,
            pieceWidth,
            0xcfcfcf
          )
          .setOrigin(0);
        boardBox.setStrokeStyle(2, 0xa25221);
        swapper.push(i * cutCount + j);
        boardBox.position = i * cutCount + j;
        boardBox.positionX = j;
        boardBox.positionY = i;
        this.boardBoxGroup.add(boardBox);
      }
    }

    const swapGap = (imageWidth - pieceWidth) / (cutCount * cutCount - 1);

    for (let i = 0; i < cutCount; i++) {
      for (let j = 0; j < cutCount; j++) {
        const puzzlePiece = this.add.image(0, 0, "mergedImage").setOrigin(0);
        puzzlePiece.setCrop(
          j * pieceWidth,
          i * pieceWidth,
          pieceWidth,
          pieceWidth
        );
        puzzlePiece.setInteractive();
        this.input.setDraggable(puzzlePiece);
        puzzlePiece.input.hitArea.setTo(
          j * pieceWidth,
          i * pieceWidth,
          pieceWidth,
          pieceWidth
        );
        puzzlePiece.swapPosition = swapper.splice(
          Math.floor(Math.random() * swapper.length),
          1
        )[0];
        puzzlePiece.setDepth(1 + puzzlePiece.swapPosition);
        puzzlePiece.position = i * cutCount + j;
        puzzlePiece.puzzleId = puzzlePiece.position;
        puzzlePiece.positionX = j;
        puzzlePiece.positionY = i;
        puzzlePiece.previousBoardBoxPosition = -1;

        puzzlePiece.y = 535 - puzzlePiece.positionY * pieceWidth;
        puzzlePiece.x =
          puzzlePiece.swapPosition * swapGap -
          puzzlePiece.positionX * pieceWidth +
          paddingAround;

        this.plugins.get("rexDropShadowPipeline").add(puzzlePiece, {
          distance: 3,
          angle: 180,
          shadowColor: 0x000000,
          alpha: 0.5,
          blur: 2,
          pixelWidth: 1,
          pixelHeight: 0,
        });
        puzzlePiece.setTint(0xe8e8e8);
        this.puzzlePieceGroup.add(puzzlePiece);
      }
    }

    this.input.on("drag", (pointer, puzzlePiece) => {
      this.tutorialArrow.visible = false;
      this.tutorialTxt.visible = false;
      puzzlePiece.x =
        pointer.x - puzzlePiece.positionX * pieceWidth - pieceWidth / 2;
      puzzlePiece.y =
        pointer.y - puzzlePiece.positionY * pieceWidth - pieceWidth / 2;
    });

    this.input.on("dragstart", (pointer, puzzlePiece) => {
      this.sfxSimpleClick.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxSimpleClick.play();
      if (puzzlePiece.scaleX != 1) {
        puzzlePiece.x =
          pointer.x - puzzlePiece.positionX * pieceWidth - pieceWidth / 2;
        puzzlePiece.y =
          pointer.y - puzzlePiece.positionY * pieceWidth - pieceWidth / 2;
      }
      this.boardBoxGroup.getChildren().forEach((boardBox) => {
        if (
          pointer.x > boardBox.x &&
          pointer.x < boardBox.x + pieceWidth &&
          pointer.y > boardBox.y &&
          pointer.y < boardBox.y + pieceWidth &&
          boardBox.isPuzzleIn
        ) {
          boardBox.isPuzzleIn = false;
        }
      });
      puzzlePiece.clearTint();
      puzzlePiece.setDepth(99);
    });

    this.input.on("dragend", (pointer, puzzlePiece) => {
      puzzlePiece.placedOnBoard = false;
      let boardCount = 0;
      this.boardBoxGroup.getChildren().forEach((boardBox) => {
        if (
          pointer.x > boardBox.x &&
          pointer.x < boardBox.x + pieceWidth &&
          pointer.y > boardBox.y &&
          pointer.y < boardBox.y + pieceWidth
        ) {
          this.sfxDrop.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxDrop.play();
          puzzlePiece.x =
            boardBox.positionX * pieceWidth -
            puzzlePiece.positionX * pieceWidth +
            paddingAround;
          puzzlePiece.y =
            boardBox.positionY * pieceWidth -
            puzzlePiece.positionY * pieceWidth +
            paddingAround +
            marginTop;
          puzzlePiece.placedOnBoard = true;
          puzzlePiece.setDepth(cutCount * cutCount + 1 - puzzlePiece.position);

          if (!boardBox.isPuzzleIn) {
            boardBox.isPuzzleIn = true;
            boardBox.puzzleId = puzzlePiece.puzzleId;
            puzzlePiece.previousBoardBoxPosition = boardBox.position;
          } else {
            let oldPuzzlePiece = this.puzzlePieceGroup
              .getChildren()
              .find(
                (puzzlePieceX) => puzzlePieceX.puzzleId == boardBox.puzzleId
              );

            let oldBoardBox = this.boardBoxGroup
              .getChildren()
              .find((boardBoxX) => boardBoxX.puzzleId == puzzlePiece.puzzleId);

            if (puzzlePiece.previousBoardBoxPosition == -1) {
              oldPuzzlePiece.previousBoardBoxPosition = -1;
              oldPuzzlePiece.placedOnBoard = false;
              oldPuzzlePiece.placedCorrectly = false;
              oldPuzzlePiece.y = 535 - oldPuzzlePiece.positionY * pieceWidth;
              oldPuzzlePiece.x =
                oldPuzzlePiece.swapPosition * swapGap -
                oldPuzzlePiece.positionX * pieceWidth +
                paddingAround;
              oldPuzzlePiece.setDepth(1 + oldPuzzlePiece.swapPosition);
              oldPuzzlePiece.setTint(0xe8e8e8);
            } else {
              oldBoardBox.isPuzzleIn = true;
              oldBoardBox.puzzleId = oldPuzzlePiece.puzzleId;
              oldPuzzlePiece.previousBoardBoxPosition = oldBoardBox.position;
              oldPuzzlePiece.x =
                oldBoardBox.positionX * pieceWidth -
                oldPuzzlePiece.positionX * pieceWidth +
                paddingAround;
              oldPuzzlePiece.y =
                oldBoardBox.positionY * pieceWidth -
                oldPuzzlePiece.positionY * pieceWidth +
                paddingAround +
                marginTop;
              oldPuzzlePiece.placedOnBoard = true;
              oldPuzzlePiece.setDepth(
                cutCount * cutCount + 1 - oldPuzzlePiece.position
              );
              if (oldBoardBox.position == oldPuzzlePiece.position) {
                oldPuzzlePiece.placedCorrectly = true;
              } else {
                oldPuzzlePiece.placedCorrectly = false;
              }
            }
          }

          boardBox.puzzleId = puzzlePiece.puzzleId;
          boardBox.isPuzzleIn = true;
          puzzlePiece.previousBoardBoxPosition = boardBox.position;

          if (boardBox.position == puzzlePiece.position) {
            puzzlePiece.placedCorrectly = true;
            let count = 0;
            this.puzzlePieceGroup.getChildren().forEach((puzzlePieceX) => {
              if (puzzlePieceX.placedCorrectly) {
                count++;
              }
            });
            if (count == cutCount * cutCount) {
              this.puzzlePieceGroup.getChildren().forEach((puzzlePieceX) => {
                puzzlePieceX.removeInteractive();
              });

              this.sfxWin.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxWin.play();

              if (this.playerMask) {
                this.playerMask.destroy();
              }
              this.playerMask = this.add
                .image(
                  this.playerAvatarObject[0].x,
                  this.playerAvatarObject[0].y,
                  "maskHappy"
                )
                .setScale(0.25)
                .setDepth(6);

              this.add
                .text(240, 535, i18n.t("miniGames.dino.niceWork"), {
                  color: "#fff",
                  fontSize: "50px",
                  fontFamily: "Sassoon, sans-serif",
                  stroke: "#000000",
                  strokeThickness: 5,
                })
                .setDepth(8)
                .setShadow(3, 3, "#000", 0, true, true)
                .setOrigin(0.5);

              let btn = this.add
                .image(240, 620, "imgOptionBtn")
                .setScale(0.25, 0.3)
                .setDepth(9)
                .setInteractive({ useHandCursor: true })
                .on(
                  "pointerdown",
                  () => {
                    btn.setAlpha(0.5)
                    btn.removeInteractive();
                    let star;
                    this.event_data = _.cloneDeep(store.state.event.data);
                    this.score += (this.difficulty - 1) * 50;
                    if (this.score >= 600 && this.score < 750) {
                      star = 1;
                    } else if (this.score >= 750 && this.score < 900) {
                      star = 2;
                    } else if (this.score >= 900) {
                      star = 3;
                    } else {
                      star = 0;
                    }

                    this.event_data.submission.countCorrect = this.countCorrect;
                    this.event_data.submission.accuracy = this.score / 10;
                    this.event_data.submission.star = star;
                    this.event_data.submission.score = this.score;
                    this.event_data.submission.submission += 1;

                    this.event_data.record.attempt += 1;
                    this.event_data.record.submission += 1;
                    this.event_data.record.topScoreOld =
                      this.event_data.record.topScore;
                    this.event_data.record.topStarOld =
                      this.event_data.record.topStar;
                    if (this.score > this.event_data.record.topScore) {
                      this.event_data.record.topScore = this.score;
                      this.event_data.record.topStar = star;
                    }

                    this.event_data.gameLoot = Math.floor(
                      Math.random() * (3 - 1 + 1) + 1
                    );
                    this.event_data.gameLootExtra = Math.floor(
                      Math.random() * (5 - 3 + 1) + 3
                    );
                    this.event_data.difficulty = this.difficulty;
                    this.event_data.boss.isCollected = true;
                    store.commit("updateEvent", this.event_data);

                    this.time.delayedCall(1000, () => {
                      router.push({
                        name: "PageEventGameScore",
                        params: {
                          id: store.state.event.data.record.levelId,
                        },
                      });
                    });
                  },
                  this
                );

              let btntxt = this.add
                .text(240, 620, i18n.t("miniGames.general.ok"), {
                  fontSize: "24px",
                  fill: "#000",
                  fontFamily: "Sassoon, sans-serif",
                })
                .setDepth(9)
                .setOrigin(0.5);

              this.tweens.add({
                targets: [btn],
                scaleX: 0.26,
                scaleY: 0.31,
                duration: 1000,
                ease: "Sine.easeInOut",
                repeat: -1,
                yoyo: true,
                onStart: () => {
                  this.tweens.add({
                    targets: [btntxt],
                    scaleY: 1.1,
                    scaleX: 1.1,
                    duration: 1000,
                    ease: "Sine.easeInOut",
                    repeat: -1,
                    yoyo: true,
                  });
                },
              });
            }
          } else {
            puzzlePiece.placedCorrectly = false;
          }
        }

        if (boardBox.isPuzzleIn) {
          boardCount++;
        }
      });

      if (boardCount == 0) {
        this.tutorialArrow.visible = true;
        this.tutorialTxt.visible = true;
      }

      if (!puzzlePiece.placedOnBoard) {
        this.sfxThrow.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxThrow.play();
        puzzlePiece.placedCorrectly = false;
        puzzlePiece.y = 535 - puzzlePiece.positionY * pieceWidth;
        puzzlePiece.x =
          puzzlePiece.swapPosition * swapGap -
          puzzlePiece.positionX * pieceWidth +
          paddingAround;
        puzzlePiece.setDepth(1 + puzzlePiece.swapPosition);
        puzzlePiece.setTint(0xe8e8e8);
      }
    });
  }

  registerSounds() {
    this.sfxDrop = this.sound.add("sfxDrop");
    this.sfxThrow = this.sound.add("sfxThrow");
    this.sfxWin = this.sound.add("sfxWin");
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
  }

  createBox() {}

  clickSetting() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    console.log("fdfdf");
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "PuzzleScene",
    });
  }

  mergeImages(background, foreground, width, height) {
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    canvas.width = background.width;
    canvas.height = background.height;
    ctx.drawImage(background, 0, 0, width, height);
    ctx.drawImage(foreground, 0, 0, width, height);
    return canvas;
  }

  update() {
    if (this.playerMask && this.playerAvatarObject[0]) {
      this.playerMask.x = this.playerAvatarObject[0].x;
      this.playerMask.y = this.playerAvatarObject[0].y;
    }
  }
}
